.works_details_card {
    color: #fff;
    padding: 50px 0;
}

.works_details_title {
    width: 95%;
    font-size: 40px;
    line-height: 47px;
}

.slogan {
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    margin-top: 20px !important;
}

.works_info {
    font-size: 14px;
    margin: 0;
}

.works__desc {
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    margin-bottom: 30px;
}

.people__title {
    font-size: 14px;
    font-weight: 400;
}

.indepth {
    padding-top: 40px;
    margin-top: 40px !important;
    border-top: 1px solid #fff;
    .indepth_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;
    }
}