.sidebar {
    display: block;
    position: fixed;
    right: -100%;
    top: 0;
    background-color: #23262b;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    transition: all 0.3s linear;
    @media only screen and (max-width: 600px)  {
        transition: all 0.2s linear;
    }
    &.active {
        right: 0;
    }
}

.sidebar_container {
    @media only screen and (max-width: 600px)  {
        padding:  20px 0 0 0 !important;
    }
}

.side_header {
    height: 110px;
    @media only screen and (max-width: 600px)  {
        padding: 0 20px;
    }
}

.toggle_close {
    background-color: transparent;
    color: #fff;
    font-size: 28px;
    border: none;
    padding: 0;
    margin: 0;
    float: right;
}

.side_navbar_one {
    padding: 0;
    margin: 0;
}

.side_nav_item {
    padding-bottom: 30px;
}

.side_nav_item_link {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.2s linear;
    &:hover {
        text-decoration: underline;
        color: #3AAA35  !important;
    }
    &.active {
        text-decoration: underline;
        color: #3AAA35  !important;
        font-weight: 700;
    }
}


.side_navs_link {
    color: rgba(255, 255, 255, 0.678) !important;
    padding: 0 !important;
    margin: 0;
    transition: all 0.2s linear;
    &:hover {
        text-decoration: underline;
        color: #3AAA35  !important;
    }
}
.side_navs {
    padding: 0;
    margin: 0;
    li {
        padding-bottom: 10px;
    }
}

.footer_navs_link {
    &.active {
        text-decoration: underline;
        color: #3AAA35  !important;
    }
}