@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: "cairo" !important;
}

a {
    text-decoration: none;
    color: #000;
}

body {
    position: relative;
    padding-top: 110px;
    &::-webkit-scrollbar {
        display: none;
    }
}

ul {
    list-style: none;
}

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 9999;
  @media only screen and (max-width: 600px)  {
    display: none;
  }
}

.cursor-dot {
  width: 0px;
  height: 0px;
  background-color: #3AAA35;
}

.cursor-dot-outline {
  width: 45px;
  height: 45px;
  background-color: #3baa35ad;
}

.links-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
}


.container {
  width: 1100px !important;
  @media only screen and (max-width: 600px)  {
    width: 100% !important;
  }
}