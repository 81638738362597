.scroll_top {
    position: fixed;
    bottom: 60px;
    right: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background-color: #3baa3571;
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    z-index: 99999;
    @media only screen and (max-width: 600px)  {
        bottom: 20px;
        right:  20px;
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px; 
        background-color: #3AAA35;
        border-radius: 50%;
    }
}