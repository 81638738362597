.header_layouti {
    height: 110px;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 55555;
    &.active {
        top: -110px;
    }
    &.white {
        background-color: #fff;
        border-bottom: 1px solid #EAEAEA;
    }
    @media only screen and (max-width: 600px)  {
        padding: 0 20px;
    }
    .container {
        .row{
            .navbar_one {
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding: 0;
                align-items: center;
                margin: 0;
                .navs {
                    white-space: nowrap;
                    font-weight: 400;
                }
            }
        }
    }
    
}


.nav_item_link{
    transition: all 0.2s linear;
    &:hover {
        color: #3AAA35 !important;
        text-decoration: underline;
    }
    &.active {
        color: #3AAA35 !important;
        text-decoration: underline;
        font-weight: 700;
    }
}
.navs {
    @media only screen and (max-width: 600px)  {
        display: none !important;
    }
}
.nav_item_btn {
    a {
        background-color: #3AAA35;
        color: #fff;
        border-radius: 20px;
        font-size: 14px;
        width: 107px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            color: #fff !important;
            text-decoration: none;
        }
        @media only screen and (max-width: 600px)  {
            width: 100%;
            height: 45px;
        }
    }
}

.toggle-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    float: right;
    @media only screen and (max-width: 600px)  {
        margin-left: 20px;
    }
}