.projects{
    margin-top: 40px;
}
.projects .project{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 90%;
    background-color: rgba(247, 249, 252, 0.714);;
}
.projects .project2{
    display: flex;
    justify-content:center;
    align-items: center;
    // width: 90%;
    background-color: rgba(247, 249, 252, 0.714);;
}
.projects .project2 img{
   margin: 0;
}
.projects .project-name h6{
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #23262B;
}
.projects .project-name p{
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #23262B;
}
.projects .proj-bottom{
    margin-top: 180px;
}