.works__details {
    height: 620px;
    width: 100%;
    display: block;
    // margin-bottom: 200px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}