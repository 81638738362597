.buildTimesless{
    margin-top: 100px;
}
.buildTimesless .text-build {
    margin-bottom: 50px;
}
.buildTimesless .text-build h2{
    font-weight: 700;
    font-size: 60px;
    line-height: 69px;
    color: #23262B;
}
.buildTimesless .text-build p{
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 40px;
    color: #23262B;
}
.buildTimesless .menu ul{
    list-style: none;
    display: inline-flex;
}
.buildTimesless .menu ul li{
    margin-right: 20px;
    border: 1px solid #42BF64;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #23262B;
    padding: 10px 20px;
    cursor: pointer;
}
.buildTimesless .menu ul li.active{
    background: #42BF64;
    color: #fff;
}