.details__header {
    width: 100%;
    height: 450px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media only screen and (max-width: 600px) {
        height: 250px;
    } 
}


.arrow_ctrl{
    padding: 40px 0;
    margin: 50px 0;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
}

.arrow_content {
    display: flex;
    align-items: center;
    gap: 10px;
    &.right {
        float: right;
        p {
            text-align: right;
        }
    }
    span{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #000;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            border-color: #3aaa35;
            color: #3aaa35;
        }
        @media only screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        } 
    }
    @media only screen and (max-width: 600px) {
        padding: 0 20px;
    }
}

.arrow_content_info {
    p {
        margin: 0;
        padding: 0;
        line-height: 18px;
        &:first-of-type {
            font-size: 14px;
            font-weight: 600;
            color: #999;
        }
        &:last-of-type {
            font-size: 18px;
            font-weight: 400;
        }
    }
    
}

.similar_apps {
    .similar_apps_title {
        h3 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            @media only screen and (max-width: 600px) {
                font-size: 18px;
            } 
        }
        a {
            color: #3aaa35;
            &:hover {
                color: #3aaa35;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        padding: 20px;
    } 
}

.info_wrap {
    p {
        margin: 0 !important;
        line-height: 28px;
        font-size: 18px;
    }
}